import { DateTime } from "luxon";
import { MondayItem } from "../common/models/monday-item-model";
import RemindersService, { GetAllRemindersResponse, ReminderResponse } from "../services.ts/reminders-service";

export interface GetAllRemindersRepositoryModel {
	reminders: Array<ReminderRepositoryModel>
}

export interface ReminderRepositoryModel {
	datetime: string,
	timezone: string,
	notes: string | null,
	user_id: number,
	account_id: number,
	board_id: number,
	item_id: number,
	schduled_time: number,
	scheduled_at: number,
	scheduled_date: DateTime,
	id: string
}

class RemindersRepository {
	private service = new RemindersService();

	async setReminders(
		dateTime: string, // Formatted as "2024-06-29T17:55"
		timezone: string,
		notes: string,
		accountId: number,
		userId: number,
		items: MondayItem[]
	): Promise<ReminderResponse | null> {
		if (items.length === 1) {
			const item = items[0];
			const result = await this.service.setReminders(
				{
					datetime: dateTime,
					timezone: timezone,
					notes: notes,
					accountId: accountId,
					userId: userId,
					boardId: item.boardId,
					itemId: item.itemId
				}
			)
			return result;
		}
		return null;
	} 

	async getAllReminders(
		userId: number,
		accountId: number,
		boardId: number
	): Promise<GetAllRemindersRepositoryModel | null> {
		const reminders: GetAllRemindersResponse | null = await this.service.getAllReminders(userId, accountId, boardId);

		if (!reminders) return null;

		// Since reminders db doesn't have an id, we create it using the scheduled_time, scheduled_at, board_id, item_id, user_id and account_id
		return {
			reminders: reminders.reminders.map((reminder) => ({
			...reminder,
				id: `scheduled_time=${reminder.schduled_time}-scheduled_at=${reminder.scheduled_at}-board_id=${reminder.board_id}-item_id=${reminder.item_id}-user_id=${reminder.user_id}-account_id=${reminder.account_id}`
			}))
		};
	}

	async deleteReminder(
		userId: number,
		accountId: number,
		itemId: number,
		boardId: number,
		scheduledTime: number,
		scheduledAt: number
	): Promise<boolean> {
		return await this.service.deleteReminder(
			{
				user_id: userId,
				account_id: accountId,
				item_id: itemId,
				board_id: boardId,
				schduled_time: scheduledTime,
				scheduled_at: scheduledAt
			}
		);
	}
}

export default RemindersRepository;