import { FunctionComponent, useState, useEffect, useMemo, useCallback } from "react";
import { useMondayContext } from "../context/monday-context-context";
import { useUserInfo } from "../user/user-info-context";
import { LoadingIndicator } from "../common/ui/loading-indicator";
import { Box, Flex, IconButton, Text } from "monday-ui-react-core";
import { GetAllRemindersRepositoryModel, ReminderRepositoryModel } from "../repository/reminders-repository";
import RemindersRepository from "../repository/reminders-repository";
import { tracking } from "../di/tracking-di";
import { TrackingEvent } from "../common/tracking/tracking-event-models";
import { ToastModel, ToastType } from "../common/models/toast-models";
import { ShowErrorToast, ShowToast } from "../common/ui/toast";
import { DateTime } from "luxon";
import { Delete, Check, CloseSmall } from "monday-ui-react-core/icons";

const SeeAllBoardRemindersScreen: FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [reminders, setReminders] = useState<GetAllRemindersRepositoryModel | null>(null);
    const [toastState, setToastState] = useState<ToastModel | null>(null);
    const [reminderInDeleteMode, setReminderInDeleteMode] = useState<Set<string>>(new Set());
    
    const { context } = useMondayContext();
    const { userInfo } = useUserInfo();
    const remindersRepository = useMemo(() => new RemindersRepository(), []);

    const boxColors = useMemo(() => ({
        backgroundColor: context?.theme === "light" || context?.theme === "hacker_theme" ? Box.backgroundColors.ALL_GREY_BACKGROUND_COLOR : Box.backgroundColors.PRIMARY_BACKGROUND_COLOR,
    }), [context?.theme]);

    const loadReminders = useCallback(async () => {
        if (!context?.boardId) return;
        
        setIsLoading(true);
        const result = await remindersRepository.getAllReminders(
            userInfo.id,
            userInfo.account.id,
            context.boardId
        );
        setReminders(result);
        setIsLoading(false);

        if (result === null) {
            setToastState({
                message: "Unable to load reminders for this board",
                type: ToastType.ERROR
            });
        }
    }, [context?.boardId, remindersRepository, userInfo.id, userInfo.account.id]);

    useEffect(() => {
        loadReminders();
    }, [loadReminders]);

    useEffect(() => {
        const trackPageView = async () => {
            await tracking.sendEvent(
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.REMINDERS_LIST_OPENED,
                { 
                  boardId: context?.boardId?.toString(),
                  userInfo: JSON.stringify(userInfo)   
                }
            );
        };
        trackPageView();
    }, [userInfo, context?.boardId]);

    const handleDeleteClick = (reminder: { id: string }) => {
        tracking.sendEvent(
            userInfo.id,
            userInfo.account.id,
            TrackingEvent.DELETE_ICON_CLICKED,
            { 
              reminderId: reminder.id 
            }
        );

        setReminderInDeleteMode(prevSet => {
            const newSet = new Set(prevSet);
            newSet.add(reminder.id);
            return newSet;
        });
    };

    const handleCancelDelete = (reminderId: string) => {
        tracking.sendEvent(
            userInfo.id,
            userInfo.account.id,
            TrackingEvent.CANCEL_DELETE_BUTTON_CLICKED,
            { 
              reminderId: reminderId 
            }
        );

        setReminderInDeleteMode(prevSet => {
            const newSet = new Set(prevSet);
            newSet.delete(reminderId);
            return newSet;
        });
    };

    const handleConfirmDelete = async (reminder: ReminderRepositoryModel) => {
        const isDeleted = await remindersRepository.deleteReminder(
            reminder.user_id,
            reminder.account_id,
            reminder.item_id,
            reminder.board_id,
            reminder.schduled_time,
            reminder.scheduled_at
        );

        tracking.sendEvent(
            userInfo.id,
            userInfo.account.id,
            TrackingEvent.CONFIRM_DELETE_BUTTON_CLICKED,
            { 
              reminderId: reminder.id 
            }
        );

        if (isDeleted) {
            // Remove from delete mode
            setReminderInDeleteMode(prevSet => {
                const newSet = new Set(prevSet);
                newSet.delete(reminder.id);
                return newSet;
            });

            // Refresh the reminders list
            await loadReminders();

            setToastState({
                message: "Reminder deleted successfully",
                type: ToastType.INFORMATIONAL
            });
        } else {
            tracking.sendEvent(
                userInfo.id,
                userInfo.account.id,
                TrackingEvent.DELETE_REMINDER_FAILED,
                { 
                  reminderId: reminder.id 
                }
            );

            setToastState({
                message: "Failed to delete reminder",
                type: ToastType.ERROR
            });
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const hasReminders = reminders?.reminders && reminders?.reminders.length > 0;

    return (
        <div className="monday-board-item-feature-container">
            <div className="see-all-board-reminders-screen-board-item-menu-container-box">
                {!hasReminders ? (
                    <div className="empty-state">
                        <Text 
                            type={Text.types.TEXT1}
                            weight={Text.weights.BOLD}
                            className="centered-text"
                        >
                            No reminders set on this board
                        </Text>
                    </div>
                ) : (
                    reminders.reminders.map((reminder) => (
                        <Box border="true" 
                            borderColor={Box.borderColors.LAYOUT_BORDER_COLOR} 
                            rounded={Box.roundeds.MEDIUM} 
                            backgroundColor={boxColors.backgroundColor} 
                            padding={Box.paddings.MEDIUM} 
                            textColor={Box.textColors.PRIMARY_TEXT_COLOR}
                            key={reminder.id}
                        >
                            <Flex direction={Flex.directions.ROW}
                                gap={Flex.gaps.SMALL}
                                justify={Flex.justify.SPACE_BETWEEN}
                            >
                                <div className="reminder-item">
                                    <Text element="p"
                                        type={Text.types.TEXT1}
                                        weight={Text.weights.NORMAL}
                                    >
                                        {reminder.notes || "No notes"}
                                    </Text>
                                    <Text 
                                        type={Text.types.TEXT2}
                                        weight={Text.weights.MEDIUM}>
                                        {DateTime.fromMillis(reminder.schduled_time)
                                            .setZone(reminder.timezone)
                                            .toLocaleString(DateTime.DATETIME_FULL)}
                                    </Text>
                                </div>
                                {reminderInDeleteMode.has(reminder.id) ? (
                                    <Flex direction={Flex.directions.COLUMN} 
                                        gap={Flex.gaps.SMALL}
                                    >
                                        <IconButton
                                            ariaLabel="Confirm delete"
                                            icon={Check}
                                            onClick={() => handleConfirmDelete(reminder)}
                                        />
                                        <IconButton
                                            ariaLabel="Cancel delete"
                                            icon={CloseSmall}
                                            onClick={() => handleCancelDelete(reminder.id)}
                                        />
                                    </Flex>
                                ) : (
                                    <IconButton
                                        ariaLabel="Delete reminder"
                                        icon={Delete}
                                        onClick={() => handleDeleteClick(reminder)}
                                    />
                                )}
                            </Flex>
                        </Box>
                    ))
                )}
                {toastState && (
                    <div className="toast-container">
                        {toastState.type === ToastType.ERROR ? (
                            <ShowErrorToast 
                                message={toastState.message} 
                                onClose={() => setToastState(null)}
                            />
                        ) : (
                            <ShowToast 
                                message={toastState.message} 
                                onClose={() => setToastState(null)}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SeeAllBoardRemindersScreen;