import "./App.css";
import "monday-ui-react-core/dist/main.css";
import SnoozeScreen from "./ui/SnoozeScreen";
import { UserInfoProvider } from "./user/user-info-context";
import { AuthProvider } from "./auth/auth-context";
import { MondaySdkProvider } from "./monday-sdk/monday-sdk-context";
import { MondayContextProvider } from "./context/monday-context-context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicyScreen from "./ui/PrivacyPolicyScreen";
import TermsOfServiceScreen from "./ui/TermsOfServiceScreen";
import PricingScreen from "./ui/PricingScreen";
import HowToUseScreen from "./ui/HowToUseScreen";
import SeeAllBoardRemindersScreen from "./ui/SeeAllBoardRemindersScreen";

const App = () => {
  return (
        <Router>
          <Routes>
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route path="/terms-of-service" element={<TermsOfServiceScreen />} />
            <Route path="/pricing" element={<PricingScreen />} />
            <Route path="/how-to-use" element={<HowToUseScreen />} />
            <Route path="*" element={
              <MondaySdkProvider>
                <MondayContextProvider>
                  <UserInfoProvider>
                    <AuthProvider>
                      <Routes>
                        {/* <Route path="/settings" element={<SettingsScreen />} /> */ /* This route is no longer required for the snooze app in the current version. Might need to bring it back if new features are added. */}
                        <Route path="/" element={<SnoozeScreen/>} />
                        <Route path="/monday/login-successful" element={<div className="error-container">Please close this window and proceed in monday.com</div>} />
                        <Route path="/monday/login-failed" element={<div className="error-container">Some went wrong. Please close the window and try again.</div>} />
                        <Route path="/monday/see-all-board-reminders" element={<SeeAllBoardRemindersScreen/>} />
                      </Routes>
                    </AuthProvider>
                  </UserInfoProvider>
                </MondayContextProvider>
              </MondaySdkProvider>
            } />
          </Routes>
        </Router>
  );
};

export default App;