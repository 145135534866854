export enum TrackingEvent {
	// Screen opens
	MONDAY_ITEM_VIEW_OPENED = "monday_item_view_opened",
	SNOOZE_PAGE_OPENED = "snooze_page_opened",
	AUTHORIZATION_SCREEN_OPENED = "authorization_screen_opened",
	SUBSCRIPTION_PAGE_OPENED = "subscription_page_opened",

	// Monday SDK failures
	MONDAY_SDK_NOT_INITIALIZED = "monday-sdk-not-initialized",
	USER_INFO_NOT_INITIALIZED = "user-info-not-initialized",

	// User Actions
	SET_REMINDER_BUTTON_CLICKED = "set_reminder_button_clicked",
	CANNOT_SET_REMINDER_IN_PAST = "cannot_set_reminder_in_past",
	SHOWING_REMINDER_ERROR_TOAST = "showing_reminder_error_toast",
	NOTES_UPDATED = "notes_updated",
	DATE_TIME_UPDATED = "date_time_update",

	// See All Reminders Screen
	REMINDERS_LIST_OPENED = "reminders_list_opened",
	DELETE_ICON_CLICKED = "delete_icon_clicked",
	CANCEL_DELETE_BUTTON_CLICKED = "cancel_delete_button_clicked",
	CONFIRM_DELETE_BUTTON_CLICKED = "confirm_delete_button_clicked",
	DELETE_REMINDER_FAILED = "delete_reminder_failed",

	// Logic events/errors
	USER_INFO_ERROR = "user_info_error",
	SESSION_TOKEN_ERROR = "session_token_error",
	STATE_TOKEN_NOT_RECEIVED = "state_token_not_received",
	MONDAY_ACCESS_FAILED = "monday_access_failed",
	HANDLE_MONDAY_LOGIN_ERROR = "handle_monday_login_error",
	REFRESH_SUBSCRIPTION_STATUS = "refresh_subscription_status",

	// API Failure Events
	MONDAY_API_KEY_FAILED = "monday_api_key_failed",
	SUBSCRIPTION_API_FAILED = "subscription_api_failed",
	REMINDER_API_FAILED = "reminder_api_failed",
	GET_ALL_REMINDERS_API_FAILED = "get_all_reminders_api_failed",
	DELETE_REMINDER_API_FAILED = "delete_reminder_api_failed"
}