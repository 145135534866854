import { useState, ChangeEvent, MouseEvent, FunctionComponent, useEffect, useMemo } from 'react';
import { TextField, TextArea } from "monday-ui-react-core";
import { ShowErrorToast, ShowToast } from '../common/ui/toast';
import { Button } from "monday-ui-react-core";
import "monday-ui-react-core/tokens";
import { useUserInfo } from '../user/user-info-context';
import { getDateTimeForTimezone, formatDateTime, parseDateTime } from '../common/utils/current-date-time';
import RemindersRepository from '../repository/reminders-repository';
import { useMondayContext } from '../context/monday-context-context';
import { TrackingEvent } from '../common/tracking/tracking-event-models';
import { ToastModel, ToastType } from '../common/models/toast-models';
import { useAuth } from '../auth/auth-context';
import { ReminderResponse } from '../services.ts/reminders-service';
import { useMondaySdk } from '../monday-sdk/monday-sdk-context';
import { sanitizeInput } from '../common/utils/sanitize-input';
import AuthorizationScreen from './AuthorizationScreen';
import { SubscriptionStatusType } from '../repository/auth-repository';
import SubscriptionScreen from './SubscriptionScreen';
import { tracking } from '../di/tracking-di';
import { LoadingIndicator } from '../common/ui/loading-indicator';
import { useNavigate } from 'react-router-dom';
interface SnoozeScreenContentProps {
  date: string;
  isReminderLoading: boolean;
  toastState: ToastModel | null;
  onDateTimeChanged: (value: string, event: Pick<ChangeEvent<Element>, "target">) => void;
  onNotesChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSetReminderClicked: (event: MouseEvent<HTMLButtonElement>) => void;
  onToastClose: () => void;
  onSeeAllBoardRemindersClicked: (event: MouseEvent<HTMLButtonElement>) => void;
}

const SnoozeScreenContent: FunctionComponent<SnoozeScreenContentProps> = (
  { date, 
    onDateTimeChanged, 
    isReminderLoading, 
    onNotesChanged, 
    onSetReminderClicked, 
    toastState, 
    onToastClose,
    onSeeAllBoardRemindersClicked
  }: SnoozeScreenContentProps
) => {
  
  const { userInfo } = useUserInfo();

  useEffect(() => {
    const trackEvent = async () => {
      await tracking.sendEvent(
          userInfo.id,
          userInfo.account.id,
          TrackingEvent.SNOOZE_PAGE_OPENED,
          {
            userInfo: JSON.stringify(userInfo)
          }
      );
    };

    trackEvent();
  }, [userInfo]); 

  return (
    <div className="monday-board-item-feature-container">
      <div className="reminders-screen-board-item-menu-container-box">
        <div className="content-wrapper">
          <div className='date-time-wrapper'>
            <TextField
              type={TextField.types.DATE_TIME}
              value={date}
              onChange={onDateTimeChanged}
              disabled={isReminderLoading}
            />
          </div>
          <div className='notes-wrapper'>
            <TextArea
              id='notes'
              label='Add any notes you want to include with the reminder'
              size='large'
              onChange={onNotesChanged}
              disabled={isReminderLoading}
            />
          </div>
        </div>
        <div className='button-container'>
        <Button
            onClick={onSeeAllBoardRemindersClicked}
            disabled={false}
            loading={false}
          >
            See all board reminders
          </Button>
          <Button
            onClick={onSetReminderClicked}
            disabled={isReminderLoading}
            loading={isReminderLoading}
          >
            Remind Me!
          </Button>
        </div>
      </div>
      {toastState !== null && (
        <div className="toast-container">
          {toastState.type === ToastType.ERROR ? (
            <ShowErrorToast message={toastState.message} onClose={onToastClose}/>
          ) : (
            <ShowToast message={toastState.message} onClose={onToastClose}/>
          )}
        </div>
      )}
    </div>
  );
}

const SnoozeScreen: FunctionComponent = () => {
  // State Management for the view
  const [date, setDate] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [isReminderLoading, setIsReminderLoading] = useState<boolean>(false);
  const [toastState, setToastState] = useState<ToastModel | null>(null);
  const { mondaySdk } = useMondaySdk();
  const navigate = useNavigate();

  // Repository for setting the reminders
  const remindersRepository: RemindersRepository = useMemo(() => new RemindersRepository(), []);

  // Additional state coming from the hooks
  const { hasMondayAccess, subscriptionStatus } = useAuth();
  const { items } = useMondayContext();
  const { userInfo } = useUserInfo();

  const onToastClose = () => {
    setToastState(null);
  }

  const onDateTimeChanged = (value: string, event: Pick<ChangeEvent<Element>, "target">) => {
    const sanitizedDateTime = sanitizeInput(value);
    setDate(sanitizedDateTime);
  };

  const onNotesChanged = (event: ChangeEvent<HTMLTextAreaElement>) => { 
    const sanitizedNotes = sanitizeInput(event.target.value);
    setNotes(sanitizedNotes);
  }

  const showToast = async (reminderSetResult: ReminderResponse) => {
    let toastType: ToastType = ToastType.INFORMATIONAL;
    if (reminderSetResult.success === false) {
      toastType = ToastType.ERROR;

      await tracking.sendEvent(
        userInfo.id,
        userInfo.account.id,
        TrackingEvent.SHOWING_REMINDER_ERROR_TOAST,
        { reminderSetResult: JSON.stringify(reminderSetResult) }
      )
    }

    setToastState(
      {
        message: reminderSetResult.message,
        type: toastType
      }
    )
  }

  const onSetReminderClicked = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const reminderDatetime = parseDateTime(date, userInfo.time_zone_identifier);
    const currentDatetimeInMs = Date.now();

    reminderDatetime.toMillis();
    await tracking.sendEvent(
      userInfo.id,
      userInfo.account.id,
      TrackingEvent.SET_REMINDER_BUTTON_CLICKED,
      {}
    )

    if (reminderDatetime.toMillis() < currentDatetimeInMs) {
      setToastState(
        {
          message: "You can only set reminder in the future!",
          type: ToastType.ERROR
        }
      )
      return
    }

    setIsReminderLoading(true);

    const reminderSetResult = await remindersRepository.setReminders(
      date,
      userInfo.time_zone_identifier,
      notes,
      userInfo.account.id,
      userInfo.id,
      items
    );

    // Value Created Event for monday tracking
    mondaySdk.execute("valueCreatedForUser");

    setIsReminderLoading(false);
    
    if (reminderSetResult !== null) {
      await showToast(reminderSetResult);
    }
  };

  const onSeeAllBoardRemindersClicked = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate("/monday/see-all-board-reminders");
  }

  useEffect(() => {
    const datetime = getDateTimeForTimezone(userInfo.time_zone_identifier);
    const formattedDateTime = formatDateTime(datetime);
    setDate(formattedDateTime);
  }, [userInfo]);

  useEffect(() => { 
    // Tracking page views
    tracking.sendEvent(
      userInfo.id,
      userInfo.account.id,
      TrackingEvent.MONDAY_ITEM_VIEW_OPENED,
      {}
    )
  }, [userInfo]);

  // Set a timer whenever a toast is shown to auto dismiss it after 5 seconds
  useEffect(() => {
    if (toastState !== null) {
      const timer = setTimeout(() => {
        setToastState(null);
      }, 5000); // 5 second timer for disappearing the toast
  
      return () => clearTimeout(timer);
    }
  }, [toastState]);

  if (hasMondayAccess === null) {
    return <LoadingIndicator />
  }

  if (!hasMondayAccess) {
    return <AuthorizationScreen />
  }

  if (subscriptionStatus.status === SubscriptionStatusType.NOT_INITIALIZED) {
    return <LoadingIndicator />
  }

  if (subscriptionStatus.status !== SubscriptionStatusType.ACTIVE_SUBSCRIPTION) {
    return <SubscriptionScreen />
  }
  
  return <SnoozeScreenContent 
    date={date}
    onDateTimeChanged={onDateTimeChanged}
    isReminderLoading={isReminderLoading}
    onNotesChanged={onNotesChanged}
    onSetReminderClicked={onSetReminderClicked}
    toastState={toastState}
    onToastClose={onToastClose}
    onSeeAllBoardRemindersClicked={onSeeAllBoardRemindersClicked}
  />
};

export default SnoozeScreen;
