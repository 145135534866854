import axios from "axios";
import { tracking } from "../di/tracking-di";
import { TrackingEvent } from "../common/tracking/tracking-event-models";
import { DateTime } from "luxon";

export interface RemindersBodyArgs {
	datetime: string;
	timezone: string;
	notes: string | null;
	accountId: number;
	userId: number;
	boardId: number;
	itemId: number;
}

export interface DeleteReminderBodyArgs {
	user_id: number,
	account_id: number,
	item_id: number,
	board_id: number,
	schduled_time: number,
	scheduled_at: number
}

export interface DeleteReminderResponse {
	isDeleted: boolean;
}

export interface ReminderResponse {
	success: boolean;
	message: string;
}

export interface GetAllRemindersResponse {
	reminders: Array<{
		datetime: string,
		timezone: string,
		notes: string | null,
		user_id: number,
		account_id: number,
		board_id: number,
		item_id: number,
		schduled_time: number,
		scheduled_at: number,
		scheduled_date: DateTime
	}>
}

class RemindersService {
	async setReminders(body: RemindersBodyArgs): Promise<ReminderResponse> {
		try {
			const response = await axios.post("https://api-snooze.cleverextensions.com/monday/reminder", body);
			return {
				success: response.status === 200,
				message: response.data.message
			};
		} catch (error: any) {
			tracking.sendEvent(
				body.userId,
				body.accountId,
				TrackingEvent.REMINDER_API_FAILED,
				{
					error: JSON.stringify(error)
				}
			);
			return {
				success: false,
				message: error.body.message
			};
		}
	}

	async getAllReminders(
		userId: number,
		accountId: number,
		boardId: number
	): Promise<GetAllRemindersResponse | null> {
		try {
			const params = new URLSearchParams({
				boardId: boardId.toString()
			});
			
			const response = await axios.get(
				`https://api-snooze.cleverextensions.com/monday/reminder/all?${params.toString()}`
			);

			if (response.status === 200) {
				return response.data as GetAllRemindersResponse;
			} else {
				return null;
			}
		} catch (error: any) {
			tracking.sendEvent(
				userId,
				accountId,
				TrackingEvent.GET_ALL_REMINDERS_API_FAILED,
				{
					error: JSON.stringify(error)
				}
			);
			return null;
		}
	}

	async deleteReminder(body: DeleteReminderBodyArgs): Promise<boolean> {
		try {
			const response = await axios.post(
				'https://api-snooze.cleverextensions.com/monday/reminder/delete', 
				body
			);
			const responseBody = response.data as DeleteReminderResponse;
			return responseBody.isDeleted;
		} catch (error: any) {
			tracking.sendEvent(
				body.user_id,
				body.account_id,
				TrackingEvent.DELETE_REMINDER_API_FAILED,
				{
					error: JSON.stringify(error)
				}
			);
			return false;
		}
	}
}

export default RemindersService;